<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { getPortalApiInfo } from "@/services/api/info.api";

onMounted(() => {
  getVersion();
});

const version = ref();

async function getVersion() {
  const response = await getPortalApiInfo();
  console.log("Info getVersion: ", response);
  if (response.data) {
    version.value = response.data.version;
  }
}
</script>

<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col cols="12" class="app-dashboard-overview">
        <v-container fluid py-0>
          <header class="app-welcome-message">
            <p>Deze applicatie beheert de pandenlijst.</p>
          </header>
          <v-row>
            <v-col>
              In de pandenlijst worden verschillende objecten bij elkaar gebracht zodat deze objecten gezamelijk een pand vormen.<br />
              Een pand kan verschillende gebruikers hebben. Deze gebruikers kunnen vanuit een ander perspectief naar een pand kijken. In onderstaand figuur zijn de diverse hoofdobjecten uitgezet.<br /><br />
              <img src="@/assets/img/datamodel.svg" /><br /><br />
              De pandenlijst heeft twee belangrijke ingangen van waaruit de objecten benaderd kunnen worden.
              <ol>
                <li>
                  Locaties, aan de rechterkant. Een locatie kan van alles zijn. Een pand, een ruimte in een pand, een aantal samengestelde ruimtes bv een verdieping. Maar een locatie kan ook een heel
                  winkelcentrum zijn of alleen maar een bemeterde lantaarnpaal of een dak met zonnepanelen. De locaties zijn onderling verbonden zodat elke locaties weer uit sublocaties kan bestaan.
                </li>
                <li>
                  Partij, aan de linkerkant. Dit is een project, bedrijf, gebruiker e.d. Een eenheid die iets wil weten van een locatie. Ook hierin zit een gelaagdheid, een partij kan onderdeel zijn
                  van een andere partij.
                </li>
              </ol>
              Daartussen zitten nog twee andere objecten
              <ul>
                <li>
                  Adresseerbaar object. Een locatie heeft een aantal kenmerken. Maar er zijn ook kenmerken waar meerdere varianten van kunnen zijn. Deze zijn ondergebracht in de 'Adresseerbare
                  objecten'. Een locatie kan bijvoorbeeld bekend zijn onder meerdere adressen. Of een locatie kan voor verschillende doeleinden verschillende bezettingsgraden hebben.
                </li>
                <li>
                  De Partij-Locatie relatie. Deze vormt de link tussen de de gebruiker en de locatie. Hiermee wordt aangegeven vanuit welk perspectief (en eventueel met welke rechten) de partij naar
                  een adreseerbaar object (en dus indirect de locatie) kijkt.
                </li>
              </ul>
              <br />
            </v-col>
          </v-row>
          <v-row v-if="version">
            <v-col> Applicatie versie: {{ version }} </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
